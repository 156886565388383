import { useMemo } from "react"
import { useTranslation } from "react-i18next"
import { User } from "model"
import { formatEmail, formatPhone } from "my-util"
import * as LoginPage from "ui/page/auth/LoginPage/path"
import { Button, Flex, Limit, Link, Pane } from "ui/ui"
import style from "./style.module.css"

export namespace SuccessPane {
    export interface Props {
        onSuccess?: () => void

        user: User
        password: string
    }
}

export function SuccessPane({ onSuccess, user, password }: Readonly<SuccessPane.Props>) {
    const [t] = useTranslation()

    // State

    const login = useMemo(getLogin, [user])
    const loginLink = useMemo(getLoginLink, [user])

    // Render

    return <Pane header={t("auth.reg.headers.success")}>
        <Flex align="start">
            <p style={{ color: "red", fontSize: "18px" }}>
                {t("auth.reg.messages.caution")}
            </p>

            <table className={style.table}>
                <tbody>
                    <tr>
                        <th>{t("auth.reg.messages.yourLogin")}</th>
                        <td>{login}</td>
                    </tr>

                    <tr>
                        <th>{t("auth.reg.messages.yourPassword")}</th>
                        <td>{password}</td>
                    </tr>
                </tbody>
            </table>

            <p>{t("auth.reg.messages.yourLoginLink")}</p>

            <Link text={loginLink}
                  to={loginLink}/>

            <Limit maxWidth="350px">
                <Button text={t("misc.actions.ok")}
                        onClick={onSuccess}/>
            </Limit>
        </Flex>
    </Pane>

    // Util

    function getLogin(): string {
        try {
            if (user.phone != null)
                return formatPhone(user.phone)

            if (user.email != null)
                return formatEmail(user.email)

            return "[UNKNOWN]"
        } catch {
            return user.phone!
        }
    }

    function getLoginLink(): string {
        return window.location.origin + LoginPage.createPath(user.id)
    }
}
