import { ReactNode, useContext, useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { getAllEffectiveAgents } from "api"
import { ACTIVE_USERS_BY_STATUS_SORTING_ORDER, getUsersByStatusSortingOrderComparator, searchUsers, User, UserStatus } from "model"
import { useUsers } from "ui/hook"
import { UserContext } from "ui/context"
import { Error403Page, SessionExpiredErrorPage } from "ui/page/error"
import { Page, UserTable } from "ui/component"
import { ErrorDisplay, Flex, Input, Loading, Padding, UserStatusColorLegendList } from "ui/ui"

export function Component() {
    const [t] = useTranslation()

    const [localUser] = useContext(UserContext)

    const storedUsers = useUsers()

    // State

    const [agents, setAgents] = useState(new Array<User>())
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(undefined as unknown)

    const [agentStatusFilter, setAgentStatusFilter] = useState<UserStatus | undefined>(undefined)

    const filteredByStatusAgents = useMemo(
        () => agentStatusFilter != null
            ? agents.filter(agent => agentStatusFilter === agent.status)
            : agents,

        [agents, agentStatusFilter],
    )

    const [searchPrompt, setSearchPrompt] = useState("")

    const visibleAgents = useMemo(
        () => searchUsers(filteredByStatusAgents, searchPrompt),
        [filteredByStatusAgents, searchPrompt],
    )

    const agentComparator = useMemo(
        () => getUsersByStatusSortingOrderComparator(ACTIVE_USERS_BY_STATUS_SORTING_ORDER),
        [],
    )

    // Loading

    useEffect(() => {
        if (!localUser?.isManager)
            return

        const controller = new AbortController()

        getAllEffectiveAgents(controller.signal)
            .then(agents => {
                setAgents(agents)
                storedUsers.addAll(agents)
            })
            .catch(error => {
                if (!controller.signal.aborted)
                    setError(error)
            })
            .finally(() => {
                if (!controller.signal.aborted)
                    setLoading(false)
            })

        return () => controller.abort()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // Render

    if (localUser == null)
        return <SessionExpiredErrorPage/>

    if (!localUser.isManager)
        return <Error403Page/>

    return <Page title={renderTitle()}
                 type="main">
        {renderContent()}
    </Page>

    function renderTitle(): ReactNode {
        return <Flex direction="row"
                     width="fit-content"
                     align="center">
            {t("sections.agents.header").toUpperCase()}

            <Input onChange={setSearchPrompt}
                   value={searchPrompt}

                   placeholder={t("misc.placeholders.search")}/>
        </Flex>
    }

    function renderContent(): ReactNode {
        if (loading)
            return <Loading/>

        if (error)
            return <ErrorDisplay error={error}/>

        if (agents.length === 0)
            return <Padding paddingTop="22px">
                {t("sections.agents.messages.no")}
            </Padding>

        return <Flex width="fit-content"
                     align="start"
                     gap="8px">
            <Padding paddingLeft="16px">
                <UserStatusColorLegendList onClick={onAgentStatusFilterClick}
                                           active={agentStatusFilter}
                                           fontSize="12px"
                                           showLegend
                                           gap="8px"/>
            </Padding>

            <UserTable comparator={agentComparator}
                       users={visibleAgents}
                       showStatuses/>
        </Flex>
    }

    // Events

    function onAgentStatusFilterClick(newStatus: UserStatus) {
        setAgentStatusFilter(oldStatus => newStatus === oldStatus ? undefined : newStatus)
    }
}
