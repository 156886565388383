import { arrayOfNonNull, isBlank } from "my-util"
import { User } from "./User"

export function searchUsers(users: User[], prompt: string): User[] {
    if (isBlank(prompt))
        return users

    const promptTokens = prompt.trim().split(/\s+/).map(s => s.toLowerCase())

    return users.filter(user => {
        const userTokens = arrayOfNonNull(
            user.phone, user.email,
            user.enFirstname, user.enLastname, user.enPatronymic,
            user.ruFirstname, user.ruLastname, user.ruPatronymic,
            user.company.enName, user.company.ruName,
            user.company.enAddress, user.company.ruAddress,
        ).map(s => s.toLowerCase())

        for (const userToken of userTokens)
            for (const promptToken of promptTokens)
                if (userToken.includes(promptToken))
                    return true

        return false
    })
}
