import { useContext, useReducer, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { tryParseNullableBoolean } from "my-util"
import { useNClicks, useResize } from "ui/hook"
import { UserContext } from "ui/context"
import { SessionExpiredErrorPage } from "ui/page/error"
import { Page, InvitesWidget, TransfersWidget, Widget, MessagesWidget } from "ui/component"
import { Circle, Flex, Link, Padding } from "ui/ui"
import { DemoPage } from "ui/page/demo"

const DEFAULT_MAX_WIDGET_CONTENT_WIDTH = "300px"
const WIDGET_HEADER_WIDTH = "120px"

const COLORFUL_WIDGETS_STORAGE_KEY = "colorful-widgets"

export function Private() {
    const [t] = useTranslation()

    const [localUser] = useContext(UserContext)

    // State

    const [maxWidgetContentWidth, setMaxWidgetContentWidth] = useState(
        DEFAULT_MAX_WIDGET_CONTENT_WIDTH,
    )

    const [colorfulWidgets, setColorfulWidgets] = useReducer(
        (_: boolean, newValue: boolean) => {
            localStorage.setItem(COLORFUL_WIDGETS_STORAGE_KEY, newValue.toString())
            return newValue
        },

        tryParseNullableBoolean(localStorage.getItem(COLORFUL_WIDGETS_STORAGE_KEY)),
    )

    // Refs

    const invitesWidgetRef = useRef<Widget.Ref | null>(null)

    // Effects

    useResize(
        () => invitesWidgetRef.current?.content,
        ({ offsetWidth }) => setMaxWidgetContentWidth(offsetWidth + "px"),
    )

    useNClicks(
        () => setColorfulWidgets(!colorfulWidgets),
        { extraDeps: [colorfulWidgets] },
    )

    // Render

    if (localUser == null)
        return <SessionExpiredErrorPage/>

    const countType = localUser.isManager
        ? "all"
        : "my"

    return <Page title={t("datetime.labels.today") + ":"}
                 compactTitleGap="16px"
                 compactTitle={
                    <Circle borderColor="red"
                            color="red"
                            radius="4px"/>
                 }
                 type="main">
        <Padding paddingTop="14px">
            <Flex align="start">
                {localUser.isAgent &&
                    <InvitesWidget sentInviteCountType={countType}
                                   newUserCountType={countType}

                                   showSentInviteCount
                                   showNewUserCount

                                   maxHeaderWidth={maxWidgetContentWidth}
                                   maxContentWidth={DEFAULT_MAX_WIDGET_CONTENT_WIDTH}

                                   headerWidth={WIDGET_HEADER_WIDTH}
                                   width="100%"

                                   colorful={colorfulWidgets}

                                   ref={invitesWidgetRef}/>
                }

                <TransfersWidget inWorkCountType={countType}
                                 newCountType={countType}

                                 maxContentWidth={maxWidgetContentWidth}
                                 headerWidth={WIDGET_HEADER_WIDTH}
                                 width="100%"

                                 colorful={colorfulWidgets}/>

                <MessagesWidget showInviteApplicationCount={localUser.isManager}
                                showUnreadMessageCount

                                maxContentWidth={maxWidgetContentWidth}
                                headerWidth={WIDGET_HEADER_WIDTH}
                                width="100%"

                                colorful={colorfulWidgets}/>
            </Flex>
        </Padding>

        {localUser.isAdmin && <div
            style={{
                position: "absolute",
                right: "1rem",
                bottom: "1rem",
            }}
        >
            <Link to={DemoPage.PATH}>Демо</Link>
        </div>}
    </Page>
}
