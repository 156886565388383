import { forwardRef, ForwardedRef } from "react"
import { getUserStatusName, getUserStatusColors } from "model"
import { UserStatus } from "model"
import { ColorLegend } from "ui/ui/output"

export namespace UserStatusColorLegend {
    export interface Props extends Omit<ColorLegend.Props, "onClick" | "color" | "legend"> {
        onClick?: (status: UserStatus) => void
        status: UserStatus

        showLegend?: boolean
    }
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UserStatusColorLegend = forwardRef((
    props: Readonly<UserStatusColorLegend.Props>,
    ref: ForwardedRef<HTMLSpanElement>
) => {
    const {
        onClick, status,
        showLegend,
     } = props

    const innerOnClick = onClick != null
        ? () => onClick(status)
        : undefined

    const legend = showLegend
        ? getUserStatusName(status)
        : undefined

    const { color } = getUserStatusColors(status)

    return <ColorLegend { ...props }

                        onClick={innerOnClick}
                        legend={legend}
                        color={color}

                        ref={ref}/>
});

UserStatusColorLegend.displayName = "UserStatusColorLegend"
