import { useLayoutEffect, useState, useRef, forwardRef,
         useImperativeHandle, ForwardedRef, useEffect, useMemo } from "react"

import { matchPath, NavLink, useLocation } from "react-router-dom"
import { Circle, Icon } from "ui/ui"
import style from "./style.module.css"

export namespace SectionLink {
    export interface Props {
        onIsCurrentChange?: (isCurrent: boolean) => void
        to: string

        text?: string

        iconSrc?: string
        iconAlt?: string

        short?: boolean

        firstRender?: boolean

        width?: string
        height?: string

        fontSize?: string
    }
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SectionLink = forwardRef((
    {
        onIsCurrentChange, to,
        text,
        iconSrc, iconAlt,
        short,
        firstRender,
        width, height,
        fontSize,
    }: Readonly<SectionLink.Props>,
    ref: ForwardedRef<HTMLAnchorElement>,
) => {
    const location = useLocation()

    // State

    const isCurrent = useMemo(
        () => matchPath(to + "/*", location.pathname) != null,
        [location, to],
    )

    const [visibleShort, setVisibleShort] = useState(short)

    // Refs

    const componentRef = useRef<HTMLAnchorElement | null>(null)

    useImperativeHandle(ref, () => componentRef.current!, [])

    // Effects

    // - State propagation

    useEffect(
        () => onIsCurrentChange?.(isCurrent),
        [isCurrent, onIsCurrentChange],
    )

    // - Animation

    useLayoutEffect(() => {
        if (short === visibleShort)
            return

        if (!short) {
            setVisibleShort(false)
            return
        }

        const component = componentRef.current

        if (component == null)
            return

        const animations = component.getAnimations({ subtree: true })

        let finishCount = 0

        for (const animation of animations)
            animation.onfinish = onFinish

        function onFinish() {
            if (++finishCount >= animations.length)
                setVisibleShort(true)
        }
    }, [short, visibleShort])

    // Render

    const iconSize = height ?? "32px"

    return <NavLink className={isCurrent ? style.CurrentSectionLink : style.SectionLink}
                    style={{ width, borderColor: short ? "transparent" : undefined, fontSize }}
                    ref={componentRef}
                    to={to}>
        {iconSrc &&
            <div
                className={style.icon}
                style={{
                    width: iconSize,
                    height: iconSize,
                    borderRadius: `calc(.5 * ${iconSize})`,
                }}
            >
                <Icon src={iconSrc}
                      alt={iconAlt ?? "Section icon"}
                      filter="brightness(0) saturate(100%) invert(29%) sepia(37%) saturate(574%) hue-rotate(164deg) brightness(99%) contrast(98%)"/>
            </div>
        }

        {text && !visibleShort &&
            <span style={{ height }}
                  className={renderTextClassName()}>
                {text}
            </span>
        }

        {isCurrent &&
            <div className={style.currentIndicator}>
                <Circle borderColor="red"
                        color="red"
                        radius="4px"/>
            </div>
        }
    </NavLink>

    function renderTextClassName(): string {
        if (firstRender)
            return style.text

        if (short)
            return style.hidingText

        return style.appearingText
    }
})

SectionLink.displayName = "SectionLink"
