import { ForwardedRef, forwardRef, ReactNode, useEffect, useMemo, useRef } from "react"
import { useTranslation } from "react-i18next"
import { plusIconUrl } from "images"
import { DeepReadonly, splicedArray } from "my-util"
import { useDragging, useStateWithDeps } from "ui/hook"
import { Flex } from "ui/ui"
import { AddDocumentsButton } from "../AddDocumentsButton"
import { DocumentUpload } from "../DocumentUpload"
import { DropZone } from "../DropZone"
import { UiDocument, copyUiDocument, UploadingUiDocument } from "../UiDocument"

export namespace DocumentListUpload {
    export interface Props {
        onChange?: (documents: UiDocument[]) => void
        documents?: UiDocument[]

        multiple?: boolean,
        accept?: string

        direction?: Flex.Direction
        align?: Flex.Align

        width?: string
        height?: string

        noDelete?: boolean

        readonly?: boolean
        disabled?: boolean

        buttonWidth?: string
        buttonText?: string
    }
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DocumentListUpload = forwardRef((
    {
        onChange, documents,
        multiple, accept,
        direction, align,
        width, height,
        noDelete,
        readonly, disabled,
        buttonWidth, buttonText,
    }: DeepReadonly<DocumentListUpload.Props>,
    ref: ForwardedRef<HTMLDivElement>,
) => {
    const [t] = useTranslation()

    const dragging = useDragging()

    const innerDirection = direction ?? "row"
    const innerAlign = align ?? "start"

    // State

    const changed = useRef(false)

    const [innerDocuments, setInnerDocuments] = useStateWithDeps((oldDocuments?: UiDocument[]) => {
        // Prefer current state if changes aren't yet propagated
        return !changed.current || oldDocuments == null
            ? (documents?.map(copyUiDocument) ?? [])
            : oldDocuments
    }, [documents])

    // Used in the renderDocumentList function

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const onDocumentChangeMemo = useMemo(() => onDocumentChange, [setInnerDocuments])

    // Effects

    // - Changes propagation

    useEffect(() => {
        if (changed.current) {
            changed.current = false
            onChange?.(innerDocuments.map(copyUiDocument))
        }
    }, [innerDocuments, onChange])

    // Render

    if (dragging)
        return <DropZone onDrop={onDrop}
                         multiple={multiple}
                         height={height}
                         ref={ref}/>

    return <Flex direction={innerDirection}
                 align={innerAlign}
                 gap="8px"
                 width={width}
                 ref={ref}>
        {renderAddButtonIfNeeded()}
        {renderDocumentList()}
    </Flex>

    function renderAddButtonIfNeeded(): ReactNode {
        const canAddFiles = !readonly && (innerDocuments.length === 0 || multiple)

        if (!canAddFiles)
            return null

        const noInnerDocuments = innerDocuments.length === 0

        const text = noInnerDocuments
            ? (buttonText ?? t("misc.actions.addFile"))
            : undefined

        return <AddDocumentsButton onClick={onAdd}

                                   text={text}

                                   buttonStyle="outline"

                                   width={buttonWidth ?? (noInnerDocuments ? "fit-content" : "40px")}
                                   height="40px"

                                   disabled={disabled}
                                   multiple={multiple}
                                   accept={accept}

                                   iconSrc={plusIconUrl}
                                   iconAlt="Plus icon"/>
    }

    function renderDocumentList(): ReactNode {
        return <Flex direction={innerDirection}
                     align={innerAlign}
                     width="fit-content"
                     gap="8px"
                     wrap>
            {innerDocuments.map(document =>
                <DocumentUpload document={document}
                                onChange={onDocumentChangeMemo}
                                noDelete={noDelete}
                                readonly={readonly}
                                disabled={disabled}
                                key={document.id}/>
            )}
        </Flex>
    }

    // Events

    function onDrop(droppedDocuments: UploadingUiDocument[]) {
        if (multiple)
            onAdd(droppedDocuments)
        else
            setInnerDocuments(droppedDocuments)
    }

    function onAdd(documentsToAdd: UploadingUiDocument[]) {
        updateInnerDocuments(oldDocuments => [...documentsToAdd, ...oldDocuments])
    }

    function onDocumentChange(changedDocument: UiDocument) {
        updateInnerDocuments(oldInnerDocuments => {
            const documentIndex = oldInnerDocuments.findIndex(({ id }) => id === changedDocument.id)

            if (documentIndex < 0)
                return oldInnerDocuments

            return splicedArray(oldInnerDocuments, documentIndex, 1, changedDocument)
                .filter(({ status }) => status !== "removed" && status !== "aborted")
        })
    }

    // Util

    function updateInnerDocuments(
        createNewDocuments: (oldInnerDocuments: UiDocument[]) => UiDocument[],
    ) {
        setInnerDocuments(oldInnerDocuments => {
            const newInnerDocuments = createNewDocuments(oldInnerDocuments)

            if (newInnerDocuments !== oldInnerDocuments)
                changed.current = true

            return newInnerDocuments
        })
    }
})

DocumentListUpload.displayName = "DocumentListUpload"
