import Decimal from "decimal.js"
import { TransferRequest } from "api"
import { Money, Transfer, TransferDirection, Product, TransferStatus, TransferProvider, LegalDocumentMeta } from "model"
import { dateToLocalDateIsoString, DeepReadonly, tryCopyDate } from "my-util"
import { copyUiDocument, FieldDocument } from "ui/component/document"

import { CurrencyRateFields,
         copyCurrencyRateFields, currencyRateFromFields,
         currencyRateToFields, currencyRateFieldsToMessage } from "./CurrencyRateFields"

import { RoutePointFields,
         copyRoutePointFields, routePointFromFields,
         routePointFieldsToRequest, routePointToFields } from "./RoutePointFields"

import { LegalDocumentFields,
         copyLegalDocumentFields, legalDocumentToFields,
         legalDocumentFromFields, legalDocumentFieldsToMessage } from "./LegalDocumentFields"

import { SignableDocumentFields,
         copySignableDocumentFields, signableDocumentToFields,
         signableDocumentFromFields, signableDocumentFieldsToRequest } from "./SignableDocumentFields"

export interface TransferFields {
    // Model object

    id?: string | null
    createdAt?: Date | null
    modifiedAt?: Date | null

    // Special users

    creatorId: string | null

    stopperId: string | null
    isStopped: boolean

    // Basic info

    status: TransferStatus
    direction: TransferDirection
    country: string

    recipient: {
        name: string
        itn: string | null
        paymentAccount: string | null
        correspondentAccount: string | null
        iban: string | null
        bank: {
            name: string
            address: string
            swift: string
        }
    }

    number: number | null

    // Moment

    moment: Date | null

    // Money

    money: Money
    currencyRate: CurrencyRateFields | null

    // Special documents

    treatyMeta: LegalDocumentMeta | null
    orderMeta: LegalDocumentMeta | null
    actMeta: LegalDocumentMeta | null

    invoice: LegalDocumentFields
    contract: LegalDocumentFields

    // Payments

    cost: Money | null
    expenses: Money | null
    agentPercent: Decimal | null
    attorneyPercent: Decimal | null
    extraAttorneyFee: Decimal | null
    agentPayedAt: Date | null

    // Comments

    comment: string | null
    adminComment: string | null
    lawyerComment: string | null
    accountantComment: string | null

    // Requisites

    companyItn: string | null

    // SWIFT

    swiftNumber: string | null
    swiftDate: Date | null

    // Collections

    documents: FieldDocument[]
    signableDocuments: SignableDocumentFields[]
    products: Product[]
    routePoints: RoutePointFields[]
    providers: TransferProvider[] | null
}

export function copyTransferFields(fields: DeepReadonly<TransferFields>): TransferFields {
    return {
        // Model object

        id: fields.id,
        createdAt: tryCopyDate(fields.createdAt),
        modifiedAt: tryCopyDate(fields.modifiedAt),

        // Special users

        creatorId: fields.creatorId,

        stopperId: fields.stopperId,
        isStopped: fields.isStopped,

        // Basic info

        status: fields.status,
        direction: fields.direction,
        country: fields.country,

        recipient: {
            name: fields.recipient.name,
            itn: fields.recipient.itn,
            paymentAccount: fields.recipient.paymentAccount,
            correspondentAccount: fields.recipient.correspondentAccount,
            iban: fields.recipient.iban,

            bank: {
                name: fields.recipient.bank.name,
                address: fields.recipient.bank.address,
                swift: fields.recipient.bank.swift,
            },
        },

        number: fields.number,

        // Moment

        moment: tryCopyDate(fields.moment),

        // Money

        money: fields.money.copy(),

        currencyRate: fields.currencyRate != null
            ? copyCurrencyRateFields(fields.currencyRate)
            : null,

        // Special documents

        treatyMeta: fields.treatyMeta?.copy() ?? null,
        orderMeta: fields.orderMeta?.copy() ?? null,
        actMeta: fields.actMeta?.copy() ?? null,

        invoice: copyLegalDocumentFields(fields.invoice),
        contract: copyLegalDocumentFields(fields.contract),

        // Payments

        cost: fields.cost?.copy() ?? null,
        expenses: fields.expenses?.copy() ?? null,
        agentPercent: fields.agentPercent,
        attorneyPercent: fields.attorneyPercent,
        extraAttorneyFee: fields.extraAttorneyFee,
        agentPayedAt: tryCopyDate(fields.agentPayedAt),

        // Comments

        comment: fields.comment,
        adminComment: fields.adminComment,
        lawyerComment: fields.lawyerComment,
        accountantComment: fields.accountantComment,

        // Requisites

        companyItn: fields.companyItn,

        // SWIFT

        swiftNumber: fields.swiftNumber,
        swiftDate: tryCopyDate(fields.swiftDate),

        // Collections

        documents: fields.documents.map(copyUiDocument),
        signableDocuments: fields.signableDocuments.map(copySignableDocumentFields),
        products: fields.products.map(product => product.copy()),
        routePoints: fields.routePoints.map(copyRoutePointFields),
        providers: fields.providers?.map(provider => provider.copy()) ?? null,
    }
}

export function transferFieldsToTransfer(fields: DeepReadonly<TransferFields>): Transfer {
    return new Transfer({
        // Model object

        id: fields.id,
        createdAt: fields.createdAt,
        modifiedAt: fields.modifiedAt,

        // Special users

        creatorId: fields.creatorId,

        stopperId: fields.stopperId,
        isStopped: fields.isStopped,

        // Basic info

        status: fields.status,
        direction: fields.direction,
        country: fields.country,
        recipient: fields.recipient,
        number: fields.number,

        // Moment

        moment: fields.moment,

        // Money

        money: fields.money,

        currencyRate: fields.currencyRate != null
            ? currencyRateFromFields(fields.currencyRate)
            : null,

        // Special documents

        treatyMeta: fields.treatyMeta,
        orderMeta: fields.orderMeta,
        actMeta: fields.actMeta,

        invoice: legalDocumentFromFields(fields.invoice),
        contract: legalDocumentFromFields(fields.contract),

        // Payments

        cost: fields.cost,
        expenses: fields.expenses,
        agentPercent: fields.agentPercent,
        attorneyPercent: fields.attorneyPercent,
        extraAttorneyFee: fields.extraAttorneyFee ?? null,
        agentPayedAt: fields.agentPayedAt,

        // Comments

        comment: fields.comment,
        adminComment: fields.adminComment,
        lawyerComment: fields.lawyerComment,
        accountantComment: fields.accountantComment,

        // Requisites

        companyItn: fields.companyItn,

        // SWIFT

        swiftNumber: fields.swiftNumber,
        swiftDate: fields.swiftDate,

        // Collections

        documentIds: fields.documents.map(({ id, document }) => document?.id ?? id),
        signableDocuments: fields.signableDocuments.map(signableDocumentFromFields),
        products: fields.products,
        routePoints: fields.routePoints.map(routePointFromFields),
        providers: fields.providers,
    })
}

export function transferToTransferFields(transfer: Transfer): TransferFields {
    return {
        // Model object

        id: transfer.id,
        createdAt: tryCopyDate(transfer.createdAt),
        modifiedAt: tryCopyDate(transfer.modifiedAt),

        // Special users

        creatorId: transfer.creatorId,

        stopperId: transfer.stopperId,
        isStopped: transfer.isStopped,

        // Basic info

        status: transfer.status,
        direction: transfer.direction,
        country: transfer.country,

        recipient: {
            name: transfer.recipient.name,
            itn: transfer.recipient.itn,
            paymentAccount: transfer.recipient.paymentAccount,
            correspondentAccount: transfer.recipient.correspondentAccount,
            iban: transfer.recipient.iban,

            bank: {
                name: transfer.recipient.bank.name,
                address: transfer.recipient.bank.address,
                swift: transfer.recipient.bank.swift,
            },
        },

        number: transfer.number,

        // Moment

        moment: transfer.moment != null
            ? new Date(transfer.moment.getTime())
            : null,

        // Money

        money: transfer.money,

        currencyRate: transfer.currencyRate != null
            ? currencyRateToFields(transfer.currencyRate)
            : null,

        // Special documents

        treatyMeta: transfer.treatyMeta,
        orderMeta: transfer.orderMeta,
        actMeta: transfer.actMeta,

        invoice: legalDocumentToFields(transfer.invoice),
        contract: legalDocumentToFields(transfer.contract),

        // Payments

        cost: transfer.cost,
        expenses: transfer.expenses,
        agentPercent: transfer.agentPercent,
        attorneyPercent: transfer.attorneyPercent,
        extraAttorneyFee: transfer.extraAttorneyFee ?? null,
        agentPayedAt: tryCopyDate(transfer.agentPayedAt),

        // Comments

        comment: transfer.comment,
        adminComment: transfer.adminComment,
        lawyerComment: transfer.lawyerComment,
        accountantComment: transfer.accountantComment,

        // Requisites

        companyItn: transfer.companyItn,

        // SWIFT

        swiftNumber: transfer.swiftNumber,
        swiftDate: tryCopyDate(transfer.swiftDate),

        // Collections

        documents: transfer.documentIds.map(id => ({ id, status: "loading" })),
        signableDocuments: transfer.signableDocuments.map(signableDocumentToFields),
        products: [...transfer.products],
        routePoints: transfer.routePoints.map(routePointToFields),

        providers: transfer.providers != null
            ? [...transfer.providers]
            : null,
    }
}

export function transferFieldsToTransferRequest(fields: DeepReadonly<TransferFields>): TransferRequest {
    return {
        // ID

        id: fields.id,

        // Basic info

        status: fields.status,
        direction: fields.direction,
        country: fields.country,
        recipient: fields.recipient,
        number: fields.number,

        // Moment

        moment: fields.moment,

        // Money

        money: {
            currency: fields.money.currency,
            amount: fields.money.amount,
        },

        currencyRate: fields.currencyRate != null
            ? currencyRateFieldsToMessage(fields.currencyRate)
            : null,

        // Special documents

        treatyMeta: fields.treatyMeta != null
            ? {
                number: fields.treatyMeta.number,

                date: fields.treatyMeta.date != null
                    ? dateToLocalDateIsoString(fields.treatyMeta.date) as any
                    : null,
            }

            : null,

        orderMeta: fields.orderMeta != null
            ? {
                number: fields.orderMeta.number,

                date: fields.orderMeta.date != null
                    ? dateToLocalDateIsoString(fields.orderMeta.date) as any
                    : null,
            }

            : null,

        actMeta: fields.actMeta != null
            ? {
                number: fields.actMeta.number,

                date: fields.actMeta.date != null
                    ? dateToLocalDateIsoString(fields.actMeta.date) as any
                    : null,
            }

            : null,

        invoice: legalDocumentFieldsToMessage(fields.invoice),
        contract: legalDocumentFieldsToMessage(fields.contract),

        // Payments

        expenses: fields.expenses != null
            ? {
                currency: fields.expenses.currency,
                amount: fields.expenses.amount.toString(),
            }

            : null,

        agentPercent: fields.agentPercent,
        attorneyPercent: fields.attorneyPercent,
        extraAttorneyFee: fields.extraAttorneyFee,
        agentPayedAt: fields.agentPayedAt,

        // Comments

        comment: fields.comment,
        adminComment: fields.adminComment,
        lawyerComment: fields.lawyerComment,
        accountantComment: fields.accountantComment,

        // Stop

        isStopped: fields.isStopped,

        // Requisites

        companyItn: fields.companyItn,

        // SWIFT

        swiftNumber: fields.swiftNumber,
        swiftDate: fields.swiftDate != null
            ? dateToLocalDateIsoString(fields.swiftDate)
            : null,

        // Collections

        documentIds: fields.documents.map(({ id, document }) => document?.id ?? id),
        signableDocuments: fields.signableDocuments.map(signableDocumentFieldsToRequest),

        products: fields.products.map(({ name, cnfeaCodes}) => ({
            cnfeaCodes: [...cnfeaCodes],
            name,
        })),

        routePoints: fields.routePoints.map(routePointFieldsToRequest),

        providers: fields.providers?.map(({ userId, providerId, percent }) => ({
            userId, providerId, percent,
        })),

        newCreatorId: fields.creatorId,
    }
}
