import Decimal from "decimal.js"
import { WithCreatorId } from "model/interfaces"
import { AbstractModelObject } from "model/AbstractModelObject"

import { dateWithTimeNulled, DeepReadonly, nullDateTime, map,
         createOrPassNullableDecimal, tryNormalizeNullableUuid, Nullable,
         collapseWhiteSpace, removeWhiteSpaceToNull, collapseSpacesToNull,
         tryCopyDate, tryNormalizeUuid, ReadonlyDate, DeepNullish, Nullish,
         collapseWhiteSpaceToNull} from "my-util"

import { CurrencyRate } from "../CurrencyRate"
import { Money } from "../Money"
import { Product } from "../Product"
import { RoutePoint } from "../RoutePoint"
import { TransferProvider } from "../TransferProvider"
import { LegalDocument } from "../LegalDocument"
import { SignableDocument } from "../SignableDocument"
import { TransferDirection, IMPORT_TRANSFER_DIRECTION } from "./TransferDirection"

import { TransferStatus, NEW_TRANSFER_STATUS, PAID_TRANSFER_STATUS,
         WAITING_MOMENT_TRANSFER_STATUS, WAITING_PAYMENT_TRANSFER_STATUS } from "./TransferStatus"
import { LegalDocumentMeta } from "../LegalDocumentMeta"

export namespace Transfer {
    // Transfer

    export interface CreationOptions
        extends
            AbstractModelObject.CreationOptions,
            Nullish<WithCreatorId>,
            Nullish<Stopper>,
            Nullish<Comments>,
            Nullish<Swift>,
            Nullish<{
                // Basic info

                status: TransferStatus
                direction: TransferDirection
                number: number

                // Moment

                moment: Date

                // Money

                money: Money | Money.CreationOptions
                currencyRate: CurrencyRate | CurrencyRate.CreationOptions

                // Special documents

                treatyMeta: LegalDocumentMeta | LegalDocumentMeta.CreationOptions
                actMeta: LegalDocumentMeta | LegalDocumentMeta.CreationOptions
                orderMeta: LegalDocumentMeta | LegalDocumentMeta.CreationOptions

                // Payments

                cost: Money | Money.CreationOptions
                expenses: Money | Money.CreationOptions
                agentPercent: Decimal.Value
                attorneyPercent: Decimal.Value
                extraAttorneyFee: Decimal.Value
                agentPayedAt: Date

                // Requisites

                companyItn: string

                // Collections

                documentIds: Iterable<string>
                signableDocuments: Iterable<SignableDocument | SignableDocument.CreationOptions>
                products: Iterable<Product | Product.CreationOptions>
                routePoints: Iterable<RoutePoint | RoutePoint.CreationOptions>
                providers: Iterable<TransferProvider | TransferProvider.CreationOptions>
            }>
    {
        // Basic info

        country: string
        recipient: RecipientCreationOptions

        // Special documents

        invoice: LegalDocument | LegalDocument.CreationOptions
        contract: LegalDocument | LegalDocument.CreationOptions
    }

    export interface CopyOptions
        extends
            AbstractModelObject.CopyOptions,
            Nullish<WithCreatorId>,
            Nullish<Stopper>,
            Nullish<Comments>,
            Nullish<Swift>,
            Nullish<{
                // Basic info

                status: TransferStatus
                direction: TransferDirection
                country: string
                recipient: RecipientCopyOptions
                number: number

                // Moment

                moment: Date

                // Money

                money: Money | Money.CopyOptions
                currencyRate: CurrencyRate | CurrencyRate.CopyOptions

                // Special documents

                treatyMeta: LegalDocumentMeta | LegalDocumentMeta.CopyOptions
                actMeta: LegalDocumentMeta | LegalDocumentMeta.CopyOptions
                orderMeta: LegalDocumentMeta | LegalDocumentMeta.CopyOptions

                invoice: LegalDocument | LegalDocument.CopyOptions
                contract: LegalDocument | LegalDocument.CopyOptions

                // Payments

                cost: Money | Money.CopyOptions
                expenses: Money | Money.CopyOptions
                agentPercent: Decimal.Value
                attorneyPercent: Decimal.Value
                extraAttorneyFee: Decimal.Value
                agentPayedAt: Date

                // Requisites

                companyItn: string

                // Collections

                documentIds: Iterable<string>
                signableDocuments: Iterable<SignableDocument | SignableDocument.CopyOptions>
                products: Iterable<Product | Product.CopyOptions>
                routePoints: Iterable<RoutePoint | RoutePoint.CopyOptions>
                providers: Iterable<TransferProvider | TransferProvider.CopyOptions>
            }>
    {}

    // SWIFT

    export interface Swift {
        swiftNumber: string
        swiftDate: Date
    }

    // Stopper

    export interface Stopper {
        stopperId: string
        isStopped: boolean
    }

    // Recipient

    export interface Recipient {
        name: string
        itn: string | null
        paymentAccount: string | null
        correspondentAccount: string | null
        iban: string | null
        bank: RecipientBank
    }

    export interface RecipientCreationOptions extends Nullish<Recipient> {
        name: string
        bank: RecipientBank
    }

    export interface RecipientCopyOptions extends DeepNullish<Recipient> {}

    export interface RecipientBank {
        name: string
        address: string
        swift: string
    }

    // Comments

    export interface Comments {
        comment: string
        adminComment: string
        lawyerComment: string
        accountantComment: string
    }
}

export class Transfer
    extends
        AbstractModelObject<Transfer.CopyOptions>

    implements
        Readonly<Nullable<WithCreatorId>>
{
    static createOrPass(arg: Transfer | DeepReadonly<Transfer.CreationOptions>): Transfer {
        return arg instanceof Transfer
            ? arg
            : new Transfer(arg)
    }

    // Fields

    // - Special users

    readonly creatorId: string | null

    readonly stopperId: string | null
    readonly isStopped: boolean

    // - Basic info

    readonly status: TransferStatus
    readonly direction: TransferDirection
    readonly country: string
    readonly recipient: DeepReadonly<Transfer.Recipient>
    readonly number: number | null

    // - Moment

    readonly moment: ReadonlyDate | null

    // - Money

    readonly money: Money
    readonly currencyRate: CurrencyRate | null

    // - Special documents

    readonly treatyMeta: LegalDocumentMeta | null
    readonly actMeta: LegalDocumentMeta | null
    readonly orderMeta: LegalDocumentMeta | null

    readonly invoice: LegalDocument
    readonly contract: LegalDocument

    // - Payments

    readonly cost: Money | null
    readonly expenses: Money | null
    readonly agentPercent: Decimal | null
    readonly attorneyPercent: Decimal | null
    readonly extraAttorneyFee: Decimal | null
    readonly agentPayedAt: ReadonlyDate | null

    // - Comments

    readonly comment: string | null
    readonly adminComment: string | null
    readonly lawyerComment: string | null
    readonly accountantComment: string | null

    // - Requisites

    readonly companyItn: string | null

    // - SWIFT

    readonly swiftNumber: string | null
    readonly swiftDate: ReadonlyDate | null

    // - Collections

    readonly documentIds: readonly string[]
    readonly signableDocuments: readonly SignableDocument[]
    readonly products: readonly Product[]
    readonly routePoints: readonly RoutePoint[]
    readonly providers: readonly TransferProvider[] | null

    // Constructor

    constructor(options: DeepReadonly<Transfer.CreationOptions>) {
        super(options)

        // Special users

        this.creatorId = tryNormalizeNullableUuid(options.creatorId)

        this.stopperId = tryNormalizeNullableUuid(options.stopperId)
        this.isStopped = options.isStopped ?? this.stopperId != null

        // Basic info

        this.status = options.status ?? NEW_TRANSFER_STATUS
        this.direction = options.direction ?? IMPORT_TRANSFER_DIRECTION
        this.country = collapseWhiteSpace(options.country)

        this.recipient = {
            name: collapseWhiteSpace(options.recipient.name),
            itn: removeWhiteSpaceToNull(options.recipient.itn ?? ""),
            paymentAccount: removeWhiteSpaceToNull(options.recipient.paymentAccount ?? ""),

            correspondentAccount: removeWhiteSpaceToNull(options.recipient.correspondentAccount ?? "")
                ?.toUpperCase() ?? null,

            iban: removeWhiteSpaceToNull(options.recipient.iban ?? ""),

            bank: {
                name: collapseWhiteSpace(options.recipient.bank.name),
                address: collapseWhiteSpace(options.recipient.bank.address),
                swift: collapseWhiteSpace(options.recipient.bank.swift),
            },
        }

        this.number = options.number ?? null

        // Moment

        this.moment = tryCopyDate(options.moment)

        // Money

        this.money = Money.createOrPass(options.money)

        this.currencyRate = options.currencyRate != null
            ? CurrencyRate.createOrPass(options.currencyRate)
            : null

        // Special documents

        this.treatyMeta = options.treatyMeta != null
            ? LegalDocumentMeta.createOrPass(options.treatyMeta)
            : null

        this.actMeta = options.actMeta != null
            ? LegalDocumentMeta.createOrPass(options.actMeta)
            : null

        this.orderMeta = options.orderMeta != null
            ? LegalDocumentMeta.createOrPass(options.orderMeta)
            : null

        this.invoice = LegalDocument.createOrPass(options.invoice)
        this.contract = LegalDocument.createOrPass(options.contract)

        // Payments

        this.cost = options.cost != null
            ? Money.createOrPass(options.cost)
            : null

        this.expenses = options.expenses != null
            ? Money.createOrPass(options.expenses)
            : null

        this.agentPercent = createOrPassNullableDecimal(options.agentPercent)
        this.attorneyPercent = createOrPassNullableDecimal(options.attorneyPercent)
        this.extraAttorneyFee = createOrPassNullableDecimal(options.extraAttorneyFee)
        this.agentPayedAt = tryCopyDate(options.agentPayedAt)

        // Comments

        this.comment = collapseSpacesToNull(options.comment ?? "")
        this.adminComment = collapseSpacesToNull(options.adminComment ?? "")
        this.lawyerComment = collapseSpacesToNull(options.lawyerComment ?? "")
        this.accountantComment = collapseSpacesToNull(options.accountantComment ?? "")

        // Requisites

        this.companyItn = collapseSpacesToNull(options.companyItn ?? "")

        // SWIFT

        this.swiftNumber = collapseWhiteSpaceToNull(options.swiftNumber ?? "")
        this.swiftDate = tryCopyDate(options.swiftDate)

        // Collections

        this.documentIds = map(options.documentIds ?? [], tryNormalizeUuid)
        this.signableDocuments = map(options.signableDocuments ?? [], SignableDocument.createOrPass)
        this.products = map(options.products ?? [], Product.createOrPass)
        this.routePoints = map(options.routePoints ?? [], RoutePoint.createOrPass)

        this.providers = options.providers != null
            ? map(options.providers, TransferProvider.createOrPass)
            : null
    }

    // Status

    get isToday(): boolean {
        if (this.moment == null)
            return false

        const today = nullDateTime(new Date()).getTime()
        const momentDay = dateWithTimeNulled(this.moment).getTime()

        return today === momentDay
    }

    get isInWork(): boolean {
        switch (this.status) {
            case WAITING_MOMENT_TRANSFER_STATUS:
            case WAITING_PAYMENT_TRANSFER_STATUS:
            case PAID_TRANSFER_STATUS:
                return true

            default:
                return false
        }
    }

    // Documents

    get allDocumentIds(): readonly string[] {
        const allDocumentIds = [...this.documentIds]

        for (const { initialId, signedId } of this.signableDocuments) {
            if (initialId != null)
                allDocumentIds.push(initialId)

            if (signedId != null)
                allDocumentIds.push(signedId)
        }

        for (const routePoint of this.routePoints)
            allDocumentIds.push(...routePoint.documentIds)

        if (this.currencyRate?.evidenceId != null)
            allDocumentIds.push(this.currencyRate.evidenceId)

        if (this.invoice.documentId != null)
            allDocumentIds.push(this.invoice.documentId)

        if (this.contract.documentId != null)
            allDocumentIds.push(this.contract.documentId)

        return allDocumentIds
    }

    // Copy

    protected override createCopy(options: DeepReadonly<Transfer.CopyOptions> = {}): Transfer {
        return new Transfer({
            // Special users

            creatorId: "creatorId" in options
                ? options.creatorId
                : this.creatorId,

            stopperId: "stopperId" in options
                ? options.stopperId
                : this.stopperId,

            isStopped: options.isStopped ?? this.isStopped,

            // Basic info

            status: options.status ?? this.status,
            direction: options.direction ?? this.direction,
            country: options.country ?? this.country,

            recipient: options.recipient != null
                ? {
                    name: options.recipient.name ?? this.recipient.name,
                    itn: options.recipient.itn ?? this.recipient.itn,

                    paymentAccount: "paymentAccount" in options.recipient
                        ? options.recipient.paymentAccount
                        : this.recipient.paymentAccount,

                    correspondentAccount: "correspondentAccount" in options.recipient
                        ? options.recipient.correspondentAccount
                        : this.recipient.correspondentAccount,

                    iban: "iban" in options.recipient
                        ? options.recipient.iban
                        : this.recipient.iban,

                    bank: options.recipient.bank != null
                        ? {
                            name: options.recipient.bank.name ?? this.recipient.bank.name,
                            address: options.recipient.bank.address ?? this.recipient.bank.address,
                            swift: options.recipient.bank.swift ?? this.recipient.bank.swift,
                        }

                        : this.recipient.bank,
                }

                : this.recipient,

            number: "number" in options
                ? options.number
                : this.number,

            // Moment

            moment: "moment" in options
                ? options.moment
                : this.moment,

            // Money

            money: this.money.copyOrPass(options.money),

            currencyRate: "currencyRate" in options
                ? options.currencyRate != null
                    ? (this.currencyRate?.copyOrPass(options.currencyRate) ?? options.currencyRate)
                    : null
                : this.currencyRate,

            // Special documents

            treatyMeta: this.treatyMeta != null
                ? this.treatyMeta.copyOrPass(options.treatyMeta)
                : options.treatyMeta,

            orderMeta: this.orderMeta != null
                ? this.orderMeta.copyOrPass(options.orderMeta)
                : options.orderMeta,

            actMeta: this.actMeta != null
                ? this.actMeta.copyOrPass(options.actMeta)
                : options.actMeta,

            invoice: this.invoice.copyOrPass(options.invoice),
            contract: this.contract.copyOrPass(options.contract),

            // Payments

            cost: "cost" in options
                ? options.cost != null
                    ? (this.cost?.copyOrPass(options.cost) ?? options.cost)
                    : null
                : this.cost,

            expenses: "expenses" in options
                ? options.expenses != null
                    ? (this.expenses?.copyOrPass(options.expenses) ?? options.expenses)
                    : null
                : this.expenses,

            agentPercent: "agentPercent" in options
                ? options.agentPercent
                : this.agentPercent,

            attorneyPercent: "attorneyPercent" in options
                ? options.attorneyPercent
                : this.attorneyPercent,

            extraAttorneyFee: "extraAttorneyFee" in options
                ? options.extraAttorneyFee
                : this.extraAttorneyFee,

            agentPayedAt: "agentPayedAt" in options
                ? options.agentPayedAt
                : this.agentPayedAt,

            // Comments

            comment: "comment" in options
                ? options.comment
                : this.comment,

            adminComment: "adminComment" in options
                ? options.adminComment
                : this.adminComment,

            lawyerComment: "lawyerComment" in options
                ? options.lawyerComment
                : this.lawyerComment,

            accountantComment: "accountantComment" in options
                ? options.accountantComment
                : this.accountantComment,

            // Requisites

            companyItn: "companyItn" in options
                ? options.companyItn
                : this.companyItn,

            // SWIFT

            swiftNumber: "swiftNumber" in options
                ? options.swiftNumber
                : this.swiftNumber,

            swiftDate: "swiftDate" in options
                ? options.swiftDate
                : this.swiftDate,

            // Collections

            documentIds: options.documentIds ?? this.documentIds,
            signableDocuments: this.copyOrders(options.signableDocuments),
            products: this.copyProducts(options.products),
            routePoints: this.copyRoutePoints(options.routePoints),
            providers: this.copyProviders(options.providers),

            // Basic

            id: options.id ?? this.id,
            createdAt: options.createdAt ?? this.createdAt,
            modifiedAt: options.modifiedAt ?? this.modifiedAt,
        })
    }

    private copyOrders(
        newOrders?: DeepReadonly<Transfer.CopyOptions["signableDocuments"]>,
    ): readonly SignableDocument[] {
        return Transfer.copyCollection(
            this.signableDocuments,
            newOrders,
            (value): value is SignableDocument => value instanceof SignableDocument,
        )
    }

    private copyProducts(
        newProducts?: DeepReadonly<Transfer.CopyOptions["products"]>,
    ): readonly Product[] {
        return Transfer.copyCollection(
            this.products,
            newProducts,
            (value): value is Product => value instanceof Product,
        )
    }

    private copyRoutePoints(
        newRoutePoints?: DeepReadonly<Transfer.CopyOptions["routePoints"]>,
    ): readonly RoutePoint[] {
        return Transfer.copyCollection(
            this.routePoints,
            newRoutePoints,
            (value): value is RoutePoint => value instanceof RoutePoint,
        )
    }

    private copyProviders(
        newProviders?: DeepReadonly<Transfer.CopyOptions["providers"]>,
    ): readonly TransferProvider[] | null {
        return this.providers != null
            ? Transfer.copyCollection(
                this.providers,
                newProviders,
                (value): value is TransferProvider => value instanceof TransferProvider,
            )

            : null
    }

    private static copyCollection<
        Item extends { copy(options: CopyOptions): Item },
        CopyOptions,
    >(
        oldItems: readonly Item[],
        newItemsOrCopyOptions: Iterable<Item | CopyOptions> | undefined | null,
        isItem: (value?: Item | CopyOptions | null) => value is Item,
    ): Item[] {
        if (newItemsOrCopyOptions == null)
            return [...oldItems]

        const result = new Array<Item>()
        const newItemsOrCopyOptionsArray = [...newItemsOrCopyOptions]

        for (
            let max = Math.max(newItemsOrCopyOptionsArray.length, oldItems.length),
                i = 0;

            i < max;

            ++i
        ) {
            const newItemOrCopyOptions = newItemsOrCopyOptionsArray.at(i)

            if (isItem(newItemOrCopyOptions)) {
                result.push(newItemOrCopyOptions)
                continue
            }

            const oldItem: Item | undefined = oldItems[i]

            if (newItemOrCopyOptions == null) {
                // Should be always true here
                // but in order to be 100% safe
                // i'll leave this if-statement

                if (oldItem != null)
                    result.push(oldItem)

                continue
            }

            if (oldItem != null)
                result.push(oldItem.copy(newItemOrCopyOptions))
        }

        return result
    }
}
