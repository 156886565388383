import { ForwardedRef, forwardRef } from "react"
import { Flex } from "ui/ui/layout"
import { ACTIVE_INVITE_STATUS, BLOCKED_USER_STATUS, UNVERIFIED_USER_STATUS, UserStatus } from "model"
import { UserStatusColorLegend } from "../UserStatusColorLegend"

export namespace UserStatusColorLegendList {
    export interface Props
        extends
            Omit<Flex.Props, "children"> ,
            Omit<UserStatusColorLegend.Props, "status" | "active">
    {
        active?: UserStatus

        hideActive?: boolean
        hideUnverified?: boolean
        hideBlocked?: boolean
    }
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UserStatusColorLegendList = forwardRef((
    props: Readonly<UserStatusColorLegendList.Props>,
    ref: ForwardedRef<HTMLDivElement>
) => {
    const {
        active,
        hideActive, hideUnverified, hideBlocked,
    } = props

    return <Flex width="fit-content"
                 direction="row"
                 { ...props }
                 ref={ref}>
        {!hideActive &&
            <UserStatusColorLegend { ...props }
                                   active={active === ACTIVE_INVITE_STATUS}
                                   status={ACTIVE_INVITE_STATUS}/>
        }

        {!hideUnverified &&
            <UserStatusColorLegend { ...props }
                                   active={active === UNVERIFIED_USER_STATUS}
                                   status={UNVERIFIED_USER_STATUS}/>
        }

        {!hideBlocked &&
            <UserStatusColorLegend { ...props }
                                   active={active === BLOCKED_USER_STATUS}
                                   status={BLOCKED_USER_STATUS}/>
        }
    </Flex>
})

UserStatusColorLegendList.displayName = "UserStatusColorLegendList"
