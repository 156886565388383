import assert from "assert"
import { ReactNode, useContext, useEffect, useMemo, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate, useParams } from "react-router-dom"

import { createTransferTreatyDownloadUrlById,
         getTransferById, deleteTransferById,
         createTransferActReportDownloadUrlById, getUserById,
         createTransferOrderDownloadUrlById, patchTransferById } from "api"

import { NEW_TRANSFER_STATUS, PAID_TRANSFER_STATUS,
         DONE_TRANSFER_STATUS, isTransferStatusDeclined, User,
         WAITING_PAYMENT_TRANSFER_STATUS, STOPPED_TRANSFER_GROUP,
         PAYMENT_EXPIRED_TRANSFER_STATUS, Transfer, TransferStatus, } from "model"

import { dateToDateString, isUuid } from "my-util"
import { TransferFields, transferToTransferFields } from "ui/fields"
import { useResize, useTransfers, useUsers } from "ui/hook"
import { UserContext } from "ui/context"
import { Error404Page, SessionExpiredErrorPage } from "ui/page/error"

import { TransferApprovalModal, TransferStopModal,
         TransferEditingForm, Page, TransferViewForm,
         TransferExpensesSetModal, TransferRejectionModal,
         TransferCurrencyRateSetModal, TransferSignableDocumentsEditingModal} from "ui/component"

import { // TransferPhaseIndicator, Padding,
         ErrorDisplay, Pane, FormControls, Link, Loading,
         TransferStatusBadge, ActionModal, TransferGroupBadge, Flex } from "ui/ui"

import { TRANSFER_ID_PARAM } from "./path"

type ModalAction =
    | "reject"
    | "delete"
    | "approve"
    | "set-currency-rate"
    | "set-expenses"
    | "edit-signable-documents"
    | "stop"

export function Component() {
    const navigate = useNavigate()

    const [t] = useTranslation()

    const [localUser] = useContext(UserContext)

    // Storages

    const storedUsers = useUsers()
    const storedTransfers = useTransfers()

    // Path

    const { [TRANSFER_ID_PARAM]: transferId } = useParams()
    const badTransferId = transferId == null || !isUuid(transferId)

    // Refs

    const viewFormRef = useRef<HTMLFormElement>(null)
    const editingFormRef = useRef<HTMLDivElement>(null)

    // State

    const [mobile, setMobile] = useState(false)

    const [editing, setEditing] = useState(false)
    const [modalAction, setModalAction] = useState(undefined as ModalAction | undefined)

    const [updating, setUpdating] = useState(false)
    const [updateError, setUpdateError] = useState(undefined as unknown)

    const [fields, setFields] = useState(undefined as TransferFields | undefined)
    const [loadingTransfer, setLoadingTransfer] = useState(localUser != null && !badTransferId)

    const [creator, setCreator] = useState(undefined as User | undefined)
    const [loadingCreator, setLoadingCreator] = useState(false)

    const [agent, setAgent] = useState(undefined as User | undefined)
    const [loadingAgent, setLoadingAgent] = useState(false)

    const loading =
        loadingTransfer ||
        loadingCreator ||
        loadingAgent

    const [error, setError] = useState(undefined as unknown)

    const usersById = useMemo(() => {
        const usersById = new Map<string, User>()

        if (creator != null)
            usersById.set(creator.id, creator)

        if (agent != null)
            usersById.set(agent.id, agent)

        return usersById
    }, [creator, agent])

    // Effects

    // - Resize handling

    useResize(
        () => editing
            ? editingFormRef.current
            : viewFormRef.current,

        viewForm => setMobile(viewForm.offsetWidth < 900),

        { extraDeps: [loading, error, editing] },
    )

    // - Transfer loading

    useEffect(() => {
        if (!loadingTransfer)
            return

        assert(transferId != null)

        const controller = new AbortController()

        getTransferById(transferId, controller.signal)
            .then(transfer => {
                storedTransfers.add(transfer)
                setFields(transferToTransferFields(transfer))
                setLoadingCreator(transfer.creatorId != null)
            })
            .catch(error => {
                if (!controller.signal.aborted)
                    setError(error)
            })
            .finally(() => {
                if (!controller.signal.aborted)
                    setLoadingTransfer(false)
            })

        return () => controller.abort()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadingTransfer])

    // - Creator loading

    useEffect(() => {
        if (!loadingCreator)
            return

        assert(fields?.creatorId != null)

        if (fields.creatorId === localUser?.id) {
            setCreator(localUser)
            setLoadingCreator(false)
            setLoadingAgent(localUser.creatorId != null)
            return
        }

        const controller = new AbortController()

        getUserById(fields.creatorId, controller.signal)
            .then(creator => {
                storedUsers.add(creator)
                setCreator(creator)
                setLoadingAgent(creator.creatorId != null)
            })
            .catch(error => {
                if (!controller.signal.aborted)
                    setError(error)
            })
            .finally(() => {
                if (!controller.signal.aborted)
                    setLoadingCreator(false)
            })

        return () => controller.abort()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadingCreator])

    // - Agent loading

    useEffect(() => {
        if (!loadingAgent)
            return

        assert(creator?.creatorId != null)

        if (creator.creatorId === localUser?.id) {
            setAgent(localUser)
            setLoadingAgent(false)
            return
        }

        const controller = new AbortController()

        getUserById(creator.creatorId, controller.signal)
            .then(agent => {
                storedUsers.add(agent)
                setAgent(agent)
            })
            .catch(error => {
                if (!controller.signal.aborted)
                    setError(error)
            })
            .finally(() => {
                if (!controller.signal.aborted)
                    setLoadingAgent(false)
            })

        return () => controller.abort()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadingAgent])

    // Render

    if (badTransferId)
        return <Error404Page/>

    if (localUser == null)
        return <SessionExpiredErrorPage/>

    return <Page title={renderTitle()}
                 type="main">
        <Flex align="start"
              height="100%">
            <Pane>{renderContent()}</Pane>
        </Flex>
    </Page>

    function renderTitle(): ReactNode {
        assert(transferId != null)

        return <Flex justify="space-between"
                     direction="row">
            <Flex direction="row">
                {t("sections.transfers.one.header").toUpperCase()}

                {fields != null && <>
                    {fields.number != null &&
                        ` ${t("misc.designations.number")} ${fields.number}`
                    }

                    {fields.treatyMeta != null && <>
                        {" ("}
                        {t("domain.transfers.labels.treaty")}

                        {fields.treatyMeta.number != null && <>
                            {" №"}
                            {fields.treatyMeta.number}
                        </>}

                        {fields.treatyMeta.date != null && <>
                            {" "}
                            {t("misc.words.from").toLowerCase()}
                            {" "}
                            {dateToDateString(fields.treatyMeta.date)}
                        </>}

                        {")"}
                    </>}

                    {fields.isStopped
                        ? <TransferGroupBadge group={STOPPED_TRANSFER_GROUP}/>
                        : <TransferStatusBadge status={fields.status}/>
                    }
                </>}
            </Flex>

            {fields != null &&
                <Flex width="fit-content"
                      direction="row">
                    <Link to={createTransferTreatyDownloadUrlById(transferId)}
                          text={t("domain.transfers.labels.treaty")}
                          whiteSpace="nowrap"
                          fontSize="16px"
                          target="_blank"/>

                    <Link to={createTransferOrderDownloadUrlById(transferId)}
                          text={t("domain.transfers.labels.order")}
                          disabled={![WAITING_PAYMENT_TRANSFER_STATUS, PAID_TRANSFER_STATUS, DONE_TRANSFER_STATUS].includes(fields.status)}
                          whiteSpace="nowrap"
                          fontSize="16px"
                          target="_blank"/>

                    <Link to={createTransferActReportDownloadUrlById(transferId)}
                          text={t("domain.transfers.labels.actReport")}
                          disabled={![WAITING_PAYMENT_TRANSFER_STATUS, PAID_TRANSFER_STATUS, DONE_TRANSFER_STATUS].includes(fields.status)}
                          whiteSpace="nowrap"
                          fontSize="16px"
                          target="_blank"/>
                </Flex>
            }
        </Flex>
    }

    function renderContent(): ReactNode {
        if (loading)
            return <Loading/>

        if (error != null)
            return <ErrorDisplay
                error={error}
                apiErrorMessageMapping={{
                    403: t("domain.transfers.messages.errors.accessDenied"),
                    404: t("domain.transfers.messages.errors.notFound"),
                }}
            />

        if (editing)
            return renderEditingForm()

        return <>
            {/* {renderPhaseIndicator()} */}
            {renderViewForm()}
            {renderModal()}
        </>
    }

    function renderEditingForm(): ReactNode {
        assert(fields != null)

        return <TransferEditingForm
            fields={fields}

            onSubmit={newFields => {
                setFields(newFields)
                setEditing(false)
            }}

            transform={newFields => {
                if (isTransferStatusDeclined(fields.status))
                    newFields.status = NEW_TRANSFER_STATUS

                return newFields
            }}

            buttons={[
                {
                    onClick() { setEditing(false) },
                    text: t("misc.actions.back"),
                    buttonStyle: "outline",
                    position: "right",
                },

                {
                    text: t("misc.actions.save"),
                    type: "submit",
                    position: "left",
                },
            ]}

            showAgentPercentInput={agent?.isAgent}

            noDocumentsDelete

            ref={editingFormRef}
        />
    }

    // function renderPhaseIndicator(): ReactNode {
    //     if (fields == null || isTransferStatusDeclined(fields.status))
    //         return null

    //     return <Padding paddingBottom="16px">
    //         <TransferPhaseIndicator status={fields.status}/>
    //     </Padding>
    // }

    function renderViewForm(): ReactNode {
        assert(fields != null)

        return <TransferViewForm onChange={setFields}
                                 fields={fields}
                                 users={usersById}
                                 error={updateError}
                                 loading={updating}
                                 buttons={renderButtons()}
                                 mobile={mobile}
                                 ref={viewFormRef}/>

        function renderButtons(): FormControls.Button[] {
            assert(fields != null && localUser != null)

            const buttons: FormControls.Button[] = [
                {
                    onClick() { navigate(-1) },
                    text: t("misc.actions.back"),
                    buttonStyle: "outline",
                    position: "right",
                },
            ]

            const { status, isStopped } = fields
            const isNew = status === NEW_TRANSFER_STATUS
            const isDone = status === DONE_TRANSFER_STATUS
            const isDeclined = isTransferStatusDeclined(status)
            const isClientEditable = isNew || isDeclined
            const isCreatedByLocalUser = localUser.id === fields.creatorId

            if (localUser.isAdmin && isDone)
                buttons.push({
                    onClick() { updateTransferStatus("paid") },
                    text: t("domain.transfers.actions.undone"),
                    position: "left",
                })

            if (!isStopped && localUser.hasRightToManageTransfers) {
                switch (status) {
                    case "new":
                        buttons.push({
                            onClick() { setModalAction("approve") },
                            text: t("misc.actions.approve"),
                            position: "left",
                        })

                        break

                    case "waiting-moment":
                        buttons.push({
                            onClick() { setModalAction("set-currency-rate") },
                            text: t("domain.currencyRates.actions.set"),
                            position: "left",
                        })

                        break

                    case "waiting-payment":
                        buttons.push(
                            {
                                onClick() { updateTransferStatus(PAID_TRANSFER_STATUS) },
                                text: t("domain.transfers.actions.paid"),
                                position: "left",
                            },

                            {
                                onClick: onPaymentExpired,
                                text: t("domain.transfers.actions.paymentExpired"),
                                buttonStyle: "outline",
                                position: "left",
                            },
                        )

                        break

                    case "paid":
                        buttons.push({
                            onClick() { setModalAction("set-expenses") },
                            text: t("misc.actions.done"),
                            position: "left",
                        })
                }

                if (!isDeclined && !isDone)
                    buttons.push({
                        onClick() { setModalAction("reject") },
                        text: t("misc.actions.reject"),
                        buttonStyle: "outline",
                        position: "left",
                    })
            }

            if ((localUser.isAdmin) ||
                (localUser.hasRightToManageTransfers && !isDone) ||
                (isCreatedByLocalUser && isClientEditable)) {
                if ((!fields.isStopped) ||
                    (localUser.isAdmin) ||
                    (localUser.hasRightToManageTransfers && localUser.hasSpecialization && fields.stopperId === localUser.id))
                    buttons.push({
                        onClick() { setEditing(true) },
                        text: t("misc.actions.edit"),
                        buttonStyle: "outline",
                        position: "left",
                    })

                buttons.push({
                    onClick() { setModalAction("delete") },
                        text: t("misc.actions.delete"),
                        buttonStyle: "outline",
                        critical: true,
                        position: "left",
                    })
            }

            if (!isStopped) {
                if (!isDone) {
                    if (localUser.hasSpecialization && localUser.hasRightToManageTransfers)
                        buttons.push({
                            onClick() { setModalAction("stop") },
                            text: t("domain.transfers.actions.stop"),
                            buttonStyle: "outline",
                            position: "left",
                            critical: true,
                        })

                    if (isCreatedByLocalUser &&
                        !localUser.hasRightToManageTransfers &&
                        fields.signableDocuments.find(document => !document.locked) != null)
                        buttons.push({
                            onClick() { setModalAction("edit-signable-documents") },
                            text: t("domain.transfers.actions.editSignableDocuments"),
                            position: "left",
                        })
                }
            } else if (
                (localUser.isAdmin) ||
                (localUser.hasSpecialization && fields.stopperId === localUser.id)
            )
                buttons.push({
                    onClick: onResume,
                    text: t("domain.transfers.actions.resume"),
                    position: "left",
                })

            return buttons
        }
    }

    // - Modal

    function renderModal(): ReactNode {
        assert(fields?.id != null)

        const width = "calc(min(80vw, 800px))"

        switch (modalAction) {
            case "reject":
                return <TransferRejectionModal onRejected={onActionComplete}
                                               onClose={onClose}
                                               transferId={fields.id}
                                               width={width}/>

            case "delete":
                return <ActionModal onYes={onDelete}
                                    onNo={onClose}
                                    closeOnSuccess
                                    critical>
                    {t("domain.transfers.messages.warnings.deletion")}
                </ActionModal>

            case "approve":
                return <TransferApprovalModal onApproved={onActionComplete}
                                              onClose={onClose}
                                              transferId={fields.id}
                                              width={width}/>

            case "set-currency-rate":
                const showAgentPercentInput =
                    fields.agentPercent != null ||
                    (creator?.creatorId != null && !creator.isManager)

                return <TransferCurrencyRateSetModal onCurrencyRateSet={onActionComplete}
                                                     onClose={onClose}
                                                     transferId={fields.id}
                                                     showAgentPercentInput={showAgentPercentInput}
                                                     agentPercent={fields.agentPercent}
                                                     extraAttorneyFee={fields.extraAttorneyFee}
                                                     width={width}/>

            case "set-expenses":
                return <TransferExpensesSetModal onSet={onActionComplete}
                                                 onClose={onClose}
                                                 transferId={fields.id}
                                                 width={width}/>

            case "edit-signable-documents":
                return <TransferSignableDocumentsEditingModal onSaved={onActionComplete}
                                                              onClose={onClose}
                                                              fields={fields}
                                                              width={width}
                                                              noDocumentDelete/>

            case "stop":
                return <TransferStopModal onStopped={onActionComplete}
                                          onClose={onClose}
                                          transferId={fields.id}
                                          width={width}/>

            default:
                modalAction satisfies undefined
                return null
        }

        async function onDelete() {
            const id = fields?.id ?? transferId

            assert(id != null)

            await deleteTransferById(id)

            navigate(-1)
        }

        function onClose() {
            setModalAction(undefined)
        }

        function onActionComplete(newTransfer: Transfer) {
            setFields(transferToTransferFields(newTransfer))
            setModalAction(undefined)
        }
    }

    // Events

    async function onPaymentExpired() {
        assert(fields?.id != null)

        setUpdating(true)

        try {
            const newTransfer = await patchTransferById(fields.id, [
                {
                    op: "replace",
                    path: "/status",
                    value: PAYMENT_EXPIRED_TRANSFER_STATUS,
                },

                {
                    op: "replace",
                    path: "/moment",
                    value: null,
                },

                {
                    op: "replace",
                    path: "/currencyRate",
                    value: null,
                },
            ])

            const newFields = transferToTransferFields(newTransfer)

            setFields(newFields)
            setUpdateError(undefined)
        } catch (error) {
            setUpdateError(error)
        } finally {
            setUpdating(false)
        }
    }

    async function onResume() {
        assert(fields?.id != null)

        setUpdating(true)

        try {
            const newTransfer = await patchTransferById(fields.id, [
                {
                    op: "replace",
                    path: "/isStopped",
                    value: false,
                },
            ])

            const newFields = transferToTransferFields(newTransfer)

            setFields(newFields)
            setUpdateError(undefined)
        } catch (error) {
            setUpdateError(error)
        } finally {
            setUpdating(false)
        }
    }

    // Util

    async function updateTransferStatus(newTransferStatus: TransferStatus) {
        assert(fields?.id != null)

        setUpdating(true)

        try {
            const newTransfer = await patchTransferById(fields.id, [
                {
                    op: "replace",
                    path: "/status",
                    value: newTransferStatus,
                }
            ])

            const newFields = transferToTransferFields(newTransfer)

            setFields(newFields)
            setUpdateError(undefined)
        } catch (error) {
            setUpdateError(error)
        } finally {
            setUpdating(false)
        }
    }
}
