import { ReactElement, ReactNode, useContext, useMemo, useState } from "react"
import styled, { css } from "styled-components"
import { Logo, MessageTargetMfaForm } from "ui/component"
import { UserContext } from "ui/context"
import { Error403Page, SessionExpiredErrorPage } from "ui/page/error"
import { Button, CheckBox, DiLangInput, Flex, Icon, MessageTarget, Modal, Padding, TransferDirectionRadio } from "ui/ui"
import { NavBar } from "./NavBar"
import { TransferDirection } from "model"
import { tickIconUrl } from "images"
import { capitalizedText, EN_NAME_INPUT_REGEX, RU_NAME_INPUT_REGEX } from "my-util"
import { validateEnName, validateRuName } from "validation"

type RegState =
    | "unregistered"
    | "message-target-verification"
    | "name-fill-in"
    | "registered"

type StakeMode =
    | "shrink"
    | "grow"

export function Component(): ReactElement {
    // State

    const [localUser] = useContext(UserContext)

    const [direction, setDirection] = useState<TransferDirection>("import")
    const [regState, setRegState] = useState<RegState>("unregistered")
    const [messageTarget, setMessageTarget] = useState("")
    const [name, setName] = useState({ en: "", ru: "" })
    const [touchedName, setTouchedName] = useState(false)
    const [acceptedRules, setAcceptedRules] = useState(false)
    const [acceptedPersonalDataProcessing, setAcceptedPersonalDataProcessing] = useState(false)

    const maxFirstState = 2.1
    const [firstStake, setFirstStake] = useState(maxFirstState)
    const [firstStakeMode, setFirstStakeMode] = useState<StakeMode>("shrink")
    const [firstStakeDelta, setFirstStakeDelta] = useState(.1)

    const maxSecondState = 3.1
    const [secondStake, setSecondStake] = useState(maxSecondState)
    const [secondStakeMode, setSecondStakeMode] = useState<StakeMode>("shrink")
    const [secondStakeDelta, setSecondStakeDelta] = useState(.1)

    const nameInvalid = useMemo(
        () =>  ({
            en: validateRuName(name.ru) != null,
            ru: validateEnName(name.en) != null,
        }),

        [name],
    )

    // Render

    if (localUser == null)
        return <SessionExpiredErrorPage/>

    if (!localUser?.isAdmin)
        return <Error403Page/>

    return <Padding padding="16px">
        <Flex align="start"
              gap="48px">
            <NavBarWrapper>
                <Flex direction="row"
                    width="fit-content"
                    gap="32px">
                    <Logo full/>

                    <NavBar/>
                </Flex>
            </NavBarWrapper>

            <H1>Трансграничные переводы в интересах {direction === "import" ? "импортёров" : "экспортёров"} РФ</H1>

            <Padding paddingLeft="48px">
                <WidgetsLayout>
                    <div style={{ gridRow: 1, gridColumn: 1 }}>
                        <TransferDirectionRadio onChange={setDirection}
                                                checked={direction}/>
                    </div>

                    <div style={{ gridRow: 1, gridColumn: "2/4" }}>
                        <Pane style={{
                            whiteSpace: "nowrap",
                            fontSize: "12px",
                            textAlign: "center",
                            backgroundColor: "transparent",
                            border: "thin solid #B4D5FD",
                        }}>
                            Нас интересует Ваше мнение. Пройдите короткий

                            {" "}

                            <LinkButton>
                                опрос
                            </LinkButton>

                            {" "}

                            и помогите нам предвосхищать ваши потребности
                        </Pane>
                    </div>

                    <NumberedPane onClick={onRegClick}
                                  style={{ gridRow: 2, gridColumn: 1 }}
                                  $number={1}
                                  $interactive>
                        <Flex gap="8px">
                            <H2>Зарегистрируйтесь</H2>
                            <NoWrapP>Телефон или e-mail</NoWrapP>
                        </Flex>
                    </NumberedPane>

                    <NumberedPane onClick={onNewTransferClick}
                                  style={{ gridRow: 2, gridColumn: 2 }}
                                  $number={2}
                                  $interactive>
                        <Flex gap="8px">
                            <H2>Создайте заявку</H2>
                            <NoWrapP>Мы поможем при необходимости</NoWrapP>
                        </Flex>
                    </NumberedPane>

                    <NumberedPane style={{ gridRow: 2, gridColumn: 3 }}
                                  $number={3}
                                  $interactive>
                        <Flex gap="8px">
                            <H2>Укажите лимит цен</H2>
                            <NoWrapP>Заявку получат провайдеры</NoWrapP>
                        </Flex>
                    </NumberedPane>

                    <NumberedPane style={{ gridRow: 3, gridColumn: 1 }}
                                  $number={4}
                                  $interactive>
                        <Flex gap="8px">
                            <H2>Подпишите договор</H2>
                            <NoWrapP>
                                {direction === "import"
                                    ? "Если договорились с провайдером"
                                    : "Предоставьте дополнительные документы"
                                }
                            </NoWrapP>
                        </Flex>
                    </NumberedPane>

                    <NumberedPane style={{ gridRow: 3, gridColumn: 2 }}
                                  $number={5}
                                  $interactive>
                        <Flex gap="8px">
                            <H2>
                                {direction === "import"
                                    ? "Проведите платёж"
                                    : "Пригласите контрагента"
                                }
                            </H2>
                            <NoWrapP>
                                {direction === "import"
                                    ? "В рублях на счёт провайдера"
                                    : "Он получит счёт в удобной ему валюте"
                                }
                            </NoWrapP>
                        </Flex>
                    </NumberedPane>

                    <NumberedPane style={{ gridRow: 3, gridColumn: 3 }}
                                  $number={6}
                                  $interactive>
                        <Flex gap="8px">
                            <H2>
                                {direction === "import"
                                    ? "Получите документы"
                                    : "Получите деньги на свой счёт"
                                }
                            </H2>
                            <NoWrapP>
                                {direction === "import"
                                    ? "Акт выполненных работ"
                                    : "После оплаты контрагентом"
                                }
                            </NoWrapP>
                        </Flex>
                    </NumberedPane>

                    <Pane style={{
                        gridRow: 4,
                        gridColumn: "1/3",
                        backgroundColor: "#d0f8d0",
                        whiteSpace: "nowrap",
                    }}>
                        <div style={{
                            position: "absolute",
                            top: "-24px",
                            textTransform: "uppercase",
                        }}>
                            Сегодня:
                        </div>

                        <Flex align="start">
                            <H2 style={{ textTransform: "uppercase"}}>
                                Операции {direction === "import" ? "импорт" : "экспорт"}
                            </H2>

                            <Flex direction="row"
                                  align="start">
                                <InvTable>
                                    <tbody>
                                        <tr>
                                            <td>Заявок:</td>
                                            <td>12</td>
                                        </tr>

                                        <tr>
                                            <td>В работе:</td>
                                            <td>6</td>
                                        </tr>

                                        <tr>
                                            <td>Торги</td>
                                            <td>6</td>
                                        </tr>
                                    </tbody>
                                </InvTable>

                                <Flex>
                                    <InvTable>
                                        <tbody>
                                            <tr>
                                                <td>Китай</td>
                                                <td>$ 100.000,0</td>
                                                <td>12.04.2025</td>
                                            </tr>

                                            <tr>
                                                <td>Турция</td>
                                                <td>€ 147.000,0</td>
                                                <td>14.04.2025</td>
                                            </tr>

                                            <tr>
                                                <td>Турция</td>
                                                <td>€ 390.000,0</td>
                                                <td>22.05.2025</td>
                                            </tr>

                                            <tr>
                                                <td>Сингапур</td>
                                                <td>$ 200.000,0</td>
                                                <td>01.05.2025</td>
                                            </tr>

                                            <tr>
                                                <td>США</td>
                                                <td>$ 950.000,0</td>
                                                <td>07.06.2025</td>
                                            </tr>

                                            <tr>
                                                <td>США</td>
                                                <td>$ 444.000,0</td>
                                                <td>12.06.2025</td>
                                            </tr>
                                        </tbody>
                                    </InvTable>
                                </Flex>
                            </Flex>
                        </Flex>
                    </Pane>

                    <Pane style={{
                        gridRow: 4,
                        gridColumn: 3,
                        alignSelf: "start",
                        backgroundColor: "#fce6e8",
                    }}>
                        <Flex align="start">
                            <H2 style={{ textTransform: "uppercase"}}>
                                Провайдеры
                            </H2>

                            <InvTable>
                                <tbody>
                                    <tr>
                                        <td>Интерсоф:</td>
                                        <td>4,1%</td>
                                    </tr>

                                    <tr>
                                        <td>Робуста:</td>
                                        <td>3,6%</td>
                                    </tr>

                                    <tr>
                                        <td>Адель:</td>
                                        <td>2,2%</td>
                                    </tr>
                                </tbody>
                            </InvTable>
                        </Flex>
                    </Pane>

                    <Pane style={{
                        gridRow: "1/5",
                        gridColumn: 4,
                        backgroundColor: "#b8d3fe",
                        height: "100%",
                    }}>
                        <Flex align="start"
                              gap="48px">
                            <H2 style={{ textTransform: "uppercase" }}>Взять заявку:</H2>

                            <InvTable>
                                <tbody>
                                    <tr>
                                        <td>Пекин КИТАЙ</td>
                                        <td>₽ - ¥ - $</td>
                                        <td>макс: 3,1%</td>
                                    </tr>

                                    <tr>
                                        <td>Бостон США</td>
                                        <td>¥ - $ - ¥</td>
                                        <td>макс: 3,2%</td>
                                    </tr>

                                    <tr>
                                        <td>Сингапур</td>
                                        <td>₽ - $ - ¥</td>
                                        <td>? %</td>
                                    </tr>
                                </tbody>
                            </InvTable>

                            <Flex align="start">
                                <H2 style={{ textTransform: "uppercase" }}>Статус торгов:</H2>

                                <b>Шеньян КИТАЙ</b>
                                <Flex direction="row"
                                      justify="space-between">
                                    {firstStake === maxFirstState
                                        ? <InvTable>
                                            <tbody>
                                                <tr>
                                                    <td>🥇 Sem*</td>
                                                    <td>2,1%</td>
                                                </tr>
                                                <tr>
                                                    <td>🥈 Roz*</td>
                                                    <td>2,2%</td>
                                                </tr>
                                                <tr>
                                                    <td>🥉 Ser*</td>
                                                    <td>2,2%</td>
                                                </tr>
                                            </tbody>
                                        </InvTable>

                                        : <InvTable>
                                            <tbody>
                                                <tr>
                                                    <td>🥇 Я</td>
                                                    <td>{firstStake.toFixed(2)}</td>
                                                </tr>
                                                <tr>
                                                    <td>🥈 Sem*</td>
                                                    <td>2,1%</td>
                                                </tr>
                                                <tr>
                                                    <td>🥉 Roz*</td>
                                                    <td>2,2%</td>
                                                </tr>
                                            </tbody>
                                        </InvTable>
                                    }

                                    {renderStakeButton(
                                        maxFirstState,
                                        firstStake, setFirstStake,
                                        firstStakeMode, setFirstStakeMode,
                                        firstStakeDelta, setFirstStakeDelta,
                                    )}
                                </Flex>

                                <b>Нью-Йорк США</b>
                                <Flex direction="row"
                                      justify="space-between">
                                    {secondStake === maxSecondState
                                        ? <InvTable>
                                            <tbody>
                                                <tr>
                                                    <td>🥇 Sem*</td>
                                                    <td>3,1%</td>
                                                </tr>
                                                <tr>
                                                    <td>🥈 Roz*</td>
                                                    <td>3,7%</td>
                                                </tr>
                                                <tr>
                                                    <td>🥉 Ser*</td>
                                                    <td>3,9%</td>
                                                </tr>
                                            </tbody>
                                        </InvTable>

                                        : <InvTable>
                                            <tbody>
                                                <tr>
                                                    <td>🥇 Я</td>
                                                    <td>{secondStake.toFixed(2)}</td>
                                                </tr>
                                                <tr>
                                                    <td>🥈 Sem*</td>
                                                    <td>3,1%</td>
                                                </tr>
                                                <tr>
                                                    <td>🥉 Roz*</td>
                                                    <td>3,7%</td>
                                                </tr>
                                            </tbody>
                                        </InvTable>
                                    }

                                    {renderStakeButton(
                                        maxSecondState,
                                        secondStake, setSecondStake,
                                        secondStakeMode, setSecondStakeMode,
                                        secondStakeDelta, setSecondStakeDelta,
                                    )}
                                </Flex>

                                <b>Сингапур</b>
                                <Flex direction="row"
                                      justify="space-between">
                                    <span>🏆 Roz 3,4%</span>
                                    <span style={{ fontSize: "18px" }}>Завершён</span>
                                </Flex>
                            </Flex>
                        </Flex>
                    </Pane>
                </WidgetsLayout>
            </Padding>
        </Flex>

        {(regState === "message-target-verification" || regState === "name-fill-in") &&
            renderRegModal()
        }
    </Padding>

    function renderStakeButton(
        maxStake: number,

        stake: number,
        setStake: (newStake: number) => void,

        mode: StakeMode,
        setMode: (newMode: StakeMode) => void,

        delta: number,
        setDelta: (newDelta: number) => void,
    ): ReactNode {
        const color = mode === "grow" ? "green" : "red"
        const hoverColor = mode === "grow" ? "lime" : "#ff9d9d"
        const stakeString = stake.toFixed(2)

        return <span style={{
            color,
            display: "inline-flex",
            gap: "16px",
            fontSize: "40px"
        }}>
            <span>
                <StakeDigit onClick={() => setDelta(1)}
                            $selected={delta === 1}
                            $hoverColor={hoverColor}>
                    {stakeString[0]}
                </StakeDigit>
                .
                <StakeDigit onClick={() => setDelta(.1)}
                            $selected={delta === .1}
                            $hoverColor={hoverColor}>
                    {stakeString[2]}
                </StakeDigit>

                <StakeDigit onClick={() => setDelta(.01)}
                            $selected={delta === .01}
                            $hoverColor={hoverColor}>
                    {stakeString[3]}
                </StakeDigit>
            </span>

            {/* {stake.toFixed(2)} */}

            <StakeButton onClick={onClick}
                         style={{ backgroundColor: color }}>
                {mode === "grow" ? "+" : "-"}
            </StakeButton>
        </span>

        function onClick() {
            if (regState !== "registered") {
                setRegState("message-target-verification")
                return
            }

            let newStake = mode === "grow"
                ? stake + delta
                : stake - delta

            if (newStake <= 0) {
                newStake = 0
                setMode("grow")
            } else if (newStake >= maxStake) {
                newStake = maxStake
                setMode("shrink")
            }

            setStake(newStake)
        }
    }

    function onRegClick() {
        if (regState === "unregistered")
            setRegState("message-target-verification")
    }

    function onNewTransferClick() {
        onRegClick()
    }

    function renderRegModal(): ReactNode {
        return <Modal onClose={onClose}
                      header="Регистрация"
                      width="500px">
            <Padding padding="16px"
                     height="260px">
                <Flex height="100%"
                      justify="center">
                    {renderContent()}
                </Flex>
            </Padding>
        </Modal>

        function onClose() {
            setRegState("unregistered")
            setMessageTarget("")
            setName({ en: "", ru: "" })
            setTouchedName(false)
            setAcceptedPersonalDataProcessing(false)
            setAcceptedRules(false)
        }

        function renderContent(): ReactNode {
            switch (regState) {
                case "message-target-verification":
                    return <MessageTargetMfaForm performMfa={() => Promise.resolve({ status: "success" })}
                                                 hidePhaseIndicator
                                                 onBack={onClose}
                                                 onSuccess={messageTarget => { setRegState("name-fill-in"); setMessageTarget(messageTarget) }}
                                                 messageTargetPlaceholder="Введите Ваш номер телефона или почту"
                                                 width="22rem"/>

                case "name-fill-in":
                    return <Flex align="start">
                        <Flex direction="row"
                              align="start">
                            <MessageTarget messageTarget={messageTarget}/>

                            <Icon src={tickIconUrl}
                                  alt="Tick icon"
                                  filter="invert(64%) sepia(96%) saturate(3701%) hue-rotate(88deg) brightness(113%) contrast(135%)"
                                  width="16px"
                                  height="16px"/>
                        </Flex>

                        <DiLangInput onChange={(en, ru) => setName({ en, ru })}
                                     enValue={name.en}
                                     ruValue={name.ru}

                                     postProcess={capitalizedText}

                                     placeholder={"Введите Ваше ФИО"}

                                     onFocus={() => setTouchedName(true)}

                                     enInvalid={touchedName && nameInvalid.en}
                                     ruInvalid={touchedName && nameInvalid.ru}

                                     ruRegex={RU_NAME_INPUT_REGEX}
                                     enRegex={EN_NAME_INPUT_REGEX}/>

                        <CheckBox label={"Согласен с правилами и условиями Системы"}
                                  onChange={setAcceptedRules}
                                  checked={acceptedRules}/>

                        <CheckBox label={"Согласен с обработкой персональных данных"}
                                  onChange={setAcceptedPersonalDataProcessing}
                                  checked={acceptedPersonalDataProcessing}/>

                        <Flex direction="row">
                            <Button onClick={() => setRegState("message-target-verification")}
                                    text="Назад"
                                    buttonStyle="text"/>

                            <Button onClick={() => setRegState("registered")}
                                    disabled={!acceptedRules || !acceptedPersonalDataProcessing || nameInvalid.ru || nameInvalid.en}
                                    text="Зарегистрироваться"/>
                        </Flex>
                    </Flex>
            }
        }
    }
}

const StakeDigit = styled.span<{
    $hoverColor?: string
    $selected?: boolean
}>`
    position: relative;
    transition: all .2s;

    &:hover {
        color: ${props => props.$hoverColor ?? "white"};
    }

    ${props => props.$selected
        ? css`
            &:after {
                display: block;
                content: " ";

                position: absolute;
                bottom: 0;
                left: 5%;

                width: 90%;
                height: 2px;

                border-radius: 1px;

                background-color: black;
            }
        `

        : ""
    }
`

const StakeButton = styled.button`
    border-radius: 50%;
    border: none;
    width: 40px;
    height: 40px;
    color: white;
    font-size: inherit;
    transition: all .2s;
    display: inline-flex;
    align-items: center;
    justify-content: center;

    &:hover {
        transform: scale(1.05);
    }

    &:active {
        transform: scale(0.95);
    }
`

const H1 = styled.h1`
    font-size: 32px;
    white-space: nowrap;
`

const H2 = styled.h2`
    font-size: 16px;
    white-space: nowrap;
`

const NoWrapP = styled.p`
    white-space: nowrap;
    font-size: 14px;
`

const LinkButton = styled.button`
    border: none;
    background: none;
    text-decoration: underline;
    cursor: pointer;
`

const NavBarWrapper = styled.div`
    width: 100%;
    padding-bottom: 1rem;
    border-bottom: thin solid #305673;
`

const WidgetsLayout = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 324px) 1.5fr;
    grid-template-rows: auto 1fr 1fr auto;
    gap: 40px;
    align-items: center;
`

const Pane = styled.div<{ $interactive?: boolean }>`
    position: relative;
    background-color: #F2F2F2;
    border-radius: 16px;
    padding: 24px 32px;
    transition: all .2s;
    cursor: default;

    ${props => props.$interactive
        ? css`
            &:hover {
                transform: scale(1.05);
            }

            &:active {
                transform: scale(0.95);
            }
        `

        : ""
    }
`

const NumberedPane = styled(Pane)<{ $number?: number }>`
    &:after {
        content: "${props => props.$number }";

        position: absolute;
        right: 16px;
        top: -16px;

        width: 16px;
        height: 16px;

        padding: 8px;

        color: white;
        background-color: #02517A;

        border-radius: 50%;

        display: grid;
        place-items: center;
    }
`

const InvTable = styled.table`
    & td {
        padding: 2px 0;
    }

    & td:not(:first-child) {
        padding-left: 16px;
    }
`
