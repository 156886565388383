import { Fragment } from "react"

import * as AllTransfersPage from "ui/page/sections/transfers/AllTransfersPage/path"
import * as CalendarPage from "ui/page/sections/calendar/CalendarPage/path"
import * as AllClientsPage from "ui/page/sections/clients/AllClientsPage/path"
import * as AllAgentsPage from "ui/page/sections/agents/AllAgentsPage/path"

import { documentsSectionIconUrl,
         helpSectionIconUrl, faqSectionIconUrl,
         agentsSectionIconUrl, calendarSectionIconUrl,
         clientsSectionIconUrl, transfersSectionIconUrl } from "images"

import { Flex, Padding } from "ui/ui"
import { SectionLink } from "ui/component"

interface Link {
    to: string
    text?: string
    iconSrc?: string
}

const linkGroups: Link[][] = [
    [
        {
            to: "#",
            text: "О нас",
            iconSrc: documentsSectionIconUrl,
        },
    ],

    [
        {
            to: AllClientsPage.PATH,
            text: "Клиентам",
            iconSrc: clientsSectionIconUrl,
        },

        {
            to: AllAgentsPage.PATH,
            text: "Агентам",
            iconSrc: agentsSectionIconUrl,
        },

        {
            to: AllTransfersPage.PATH,
            text: "Заявки",
            iconSrc: transfersSectionIconUrl,
        },

        {
            to: CalendarPage.PATH,
            text: "Календарь",
            iconSrc: calendarSectionIconUrl,
        },

        {
            to: "#",
            text: "Помощь",
            iconSrc: helpSectionIconUrl,
        },

        {
            to: "#",
            text: "FAQ",
            iconSrc: faqSectionIconUrl,
        },
    ],
]

export function NavBar() {
    return <Flex direction="row">
        {linkGroups.map((links, i) =>
            <Fragment key={i}>
                {i > 0 &&
                    <Padding paddingLeft="20px"/>
                }

                <Flex direction="row"
                    align="start">
                    {links.map(({ to, text, iconSrc }, j) =>
                        <SectionLink iconSrc={iconSrc}
                                     fontSize="12px"
                                     short={false}
                                     text={text}
                                     to={to}
                                     key={j}/>
                    )}
                </Flex>
            </Fragment>
        )}
    </Flex>
}
